<template>
  <div class="contentmain">
    <div class="header-box">
      <div class="searchbox">
        <div class="sb-one">
          <div class="city font16" @click="cityshow">
            <span>{{ topPageInfo.city }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="inputmain">
            <el-input
              v-model="topPageInfo.keyword"
              placeholder="推荐搜索：企业名称、岗位职能、技能标签等"
            ></el-input>
          </div>
          <div class="s-btn" @click="toresult()">搜索</div>
        </div>
        <div class="tologin font16" v-if="!isLogin">去登录可精准匹配</div>
      </div>

      <div class="li qiye-type">
        <!-- 求职意向 -->
        <div class="tit" v-if="isLogin && topWantList.length > 0">求职意向:</div>
        <span v-for="(item5, index5) in topWantList"
              class="active-c"
            v-show="topPageInfo.userJobId === item5.id"
            :key="index5" >
          {{ item5.jobName }}</span>
        <!-- 技能 -->
          <div class="tit" style="cursor: pointer; margin-left: 110px" @click="jineng()">
            技能<i class="el-icon-arrow-down"></i>：
          </div>
          <template v-if="topSkillList.length == 0">
            <span style="margin-left: 5px">无</span>
          </template>
          <template v-else>
          <span v-for="(item5, index5) in topSkillList" :key="index5">
            {{
              item5.label
            }}</span>
          </template>
      </div>

      <div class="preparation" style="margin: 20px 0">
        <div class="zidingyi">
          <div class="d-name">薪资：</div>
          <div class="d-input">
            <el-select
              :value="salaryValue"
              placeholder="请选择薪资"
              @change="onSelect"
              style="width: 130px" >
              <el-option label="不限" value=""></el-option>
              <el-option label="3K以下" :value="3000"></el-option>
              <el-option label="3-5K" :value="5000"></el-option>
              <el-option label="5-10K" :value="10000"></el-option>
              <el-option label="10-15K" :value="15000"></el-option>
              <el-option label="15-20K" :value="20000"></el-option>
              <el-option label="20-30K" :value="30000"></el-option>
              <el-option label="30-50K" :value="50000"></el-option>
              <el-option label="50K以上" :value="60000"></el-option>
              <el-option :label="topPageInfo.salaryHigh && salaryCustom
                                            ? (parseFloat((pageInfo.salaryLow / 1000).toFixed(2))
                                                + '-'
                                                + parseFloat((pageInfo.salaryHigh / 1000).toFixed(2)) +'K')
                                            :'自定义'" :value="0"></el-option>
            </el-select>
          </div>
        </div>
        <div class="education-select">
          <div class="d-name">学历：</div>
          <el-select
            v-model="topSelectEducations"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in educationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="zidingyi">
          <div class="d-name">工作经验：</div>
          <div class="d-input">
            <el-select
              v-model="topPageInfo.expHigh"
              placeholder="请选择工作经验"
              style="width: 130px"
              @change="onSelect2"
            >
              <!-- <el-option
								v-for="item in expLowOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option> -->
              <el-option label="不限" value=""></el-option>
              <el-option label="1年以内" :value="1"></el-option>
              <el-option label="1-3年" :value="3"></el-option>
              <el-option label="3~5年" :value="5"></el-option>
              <el-option label="5-10年" :value="10"></el-option>
              <el-option label="10年以上" :value="99"></el-option>
            </el-select>
            <!-- <span>-</span>
						<el-select v-model="topPageInfo.expHigh" placeholder="请选择最高" style="width: 130px;">
							<el-option
								v-for="item in expHighOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select> -->
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="30%"
    >
      <div style="padding: 0 20px">
        <p style="text-align: center; font-size: 20px; padding-top: 20px">
          请输入您想要的薪资(元)
        </p>
        <el-form
          :model="topPageInfo"
          ref="numberValidateForm"
          label-width="100px"
        >
          <el-form-item
            label="最低薪资"
            prop="salaryLow"
            :rules="[{ type: 'number', message: '薪资必须为数字值' }]"
          >
            <el-input
              type="age"
              v-model.number="topPageInfo.salaryLow"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="最大薪资"
            prop="salaryHigh"
            :rules="[{ type: 'number', message: '薪资必须为数字值' }]"
          >
            <el-input
              type="age"
              v-model.number="topPageInfo.salaryHigh"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="customSalaryConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  props: [
    "pageInfo",
    "skillList",
    "wantlist",
    "selectEducations",
    "expHighOptions",
    "expLowOptions",
    "educationOptions",
    "gzHighOptions",
    "gzLowOptions",
  ],
  data() {
    return {
      dialogVisible: false,
      isLogin: false,

      salaryValue: "",
      salaryCustom: false,
      topPageInfo: {
        salaryLow: 0,
        salaryHigh: 0,
        expLow: 0,
        expHigh: 0,
        city: "",
        keyword: "",
        educationTypes: [],
        skillIds: [],
      },
      topSkillList: [],
      topSelectEducations: [],
      topWantList: [],
    };
  },
  created() {
    let isLogin = sessionStorage.getItem("userInfo");
    this.isLogin = isLogin;
    this.topPageInfo = this.pageInfo;
    this.topSkillList = this.skillList;
    this.topWantList = this.wantlist;
    this.topSelectEducations = this.selectEducations;
  },
  mounted() {},
  methods: {
    customSalaryConfirm(){
      this.dialogVisible = false;
      if(this.topPageInfo.salaryLow > this.topPageInfo.salaryHigh){
        let temp = this.topPageInfo.salaryLow;
        this.topPageInfo.salaryLow = this.topPageInfo.salaryHigh;
        this.topPageInfo.salaryHigh = temp;
      }
    },
    onSelect(e) {
      // console.log("+++=", e);
      this.salaryValue = e;
      this.topPageInfo.salaryHigh = e;
      if (e == "") {
        this.topPageInfo.salaryLow = "";
      }
      if (e == 3000) {
        this.topPageInfo.salaryLow = 0;
      }
      if (e == 5000) {
        this.topPageInfo.salaryLow = 3000;
      }
      if (e == 10000) {
        this.topPageInfo.salaryLow = 5000;
      }
      if (e == 15000) {
        this.topPageInfo.salaryLow = 10000;
      }
      if (e == 20000) {
        this.topPageInfo.salaryLow = 15000;
      }
      if (e == 30000) {
        this.topPageInfo.salaryLow = 20000;
      }
      if (e == 50000) {
        this.topPageInfo.salaryLow = 30000;
      }
      if (e == 0) {
        this.salaryCustom = true;
        this.topPageInfo.salaryLow = 0;
        this.topPageInfo.salaryHigh = 0;
        this.dialogVisible = true;
      } else {
        this.salaryCustom = false;
      }
    },
    onSelect2(e) {
      // console.log("+++=", e);
      if (e == "") {
        this.topPageInfo.expLow = 0;
        this.topPageInfo.exHigh = 0;
      }
      if (e == 1) {
        this.topPageInfo.expLow = 0;
      }
      if (e == 3) {
        this.topPageInfo.expLow = 1;
      }
      if (e == 5) {
        this.topPageInfo.expLow = 3;
      }
      if (e == 10) {
        this.topPageInfo.expLow = 5;
      }
      if (e == 99) {
        this.topPageInfo.expLow = 10;
        this.topPageInfo.expHigh = 0;
      }
    },
    cityshow() {
      //城市选择
      Bus.$emit("citystuts", {
        show: true,
        selectCity: this.topPageInfo.city,
        callback: (name) => {
          this.topPageInfo.city = name;
        },
      });
    },
    jineng() {
      //选择技能
      // console.log("$choose skill ---- position.topPage ----- skillIds list = ", this.topPageInfo.skillIds,);
      let model = 1;
      let event = "jinengstuts"
      let selected = this.topPageInfo.skillIds;
      if(selected !== null && selected.length !== 0){
        model = 0;
        let tempId = selected[0] - 0;
        if(tempId > 3395 && tempId < 3661) {  // 通过 skill 字典中ID序列号，判断 skill 类型
          event = "chipSkillStatus"
        } else if (tempId > 3361 && tempId < 3760) {
          event = "nonTechStatus"
        }
      }
      Bus.$emit(event, {
            show: true,
            model: model,
            skillIds: selected,
        callback: (arr) => {
          // this.topSkillList = this.topSkillList.concat(arr);
          this.topSkillList = arr;
          let idArr = [];
          this.topSkillList.map((item) => {
            idArr.push(item.value);
          });
          this.topPageInfo.skillIds = idArr.join(",");
        },
      });
    },
    toresult() {
      if (this.wantlist.length == 0) {
        this.$alert(
            this.isLogin ? "检测到您的简历未完善，请前往简历中心填写求职意向" : "请先登录",
            "提示",
            {
              confirmButtonText: "确定",
            }
        );
        return;
      }
      this.topPageInfo.educationTypes = this.topSelectEducations.join(",");
      this.$router.push("/serachresult");
      let searchJobConfig = {
        selectEducations: this.topSelectEducations,
        pageInfo: this.topPageInfo,
      };
      sessionStorage.setItem(
        "searchJobConfig",
        JSON.stringify(searchJobConfig)
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  border-radius: 6px;
}

.header-box {
  display: flex;
  // align-items: center;
  flex-direction: column;
  min-height: 88px;
  padding-top: 20px;
  // background-color: #000080;
  transform: translate(-9px, 0px);
  .qiye-type {
    color: #111111;
    border-bottom: 1px solid #dddddd;
    display: flex;
    align-items: center;
    padding: 20px 0;
    span:hover {
      cursor: pointer;
      color: #00bcff;
    }
    span {
      margin-left: 30px;
    }
    .active-c {
      color: #00bcff;
    }
  }
  .preparation {
    display: flex;
    align-items: center;
    padding-top: 20px;
    .preparation-main {
      margin-right: 70px;
      .el-dropdown-link:hover {
        cursor: pointer;
      }
    }
    .zidingyi {
      margin-right: 50px;
      display: flex;
      align-items: center;
      .d-name {
        color: #606266;
        font-size: 14px;
        margin-right: 10px;
      }
      .d-input {
        display: flex;
        align-items: center;
        span {
          margin: 0 10px;
        }
        /deep/ .el-input__inner {
          border-radius: 0px;
        }
      }
    }
    .education-select {
      display: flex;
      align-items: center;
      margin-right: 50px;
      .d-name {
        color: #606266;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  .el-dropdown-link {
    color: #111111 !important;
    font-size: 16px;
  }
  .el-dropdown-link:hover {
    cursor: pointer;
  }
}
.searchbox {
  width: 100%;
  // background-color: #000080;
  display: flex;
  align-items: center;
  .tologin {
    width: 200px;
    height: 50px;
    border: 1px solid #00bcff;
    margin-left: 20px;
    color: #00bcff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sb-one {
    display: flex;
    align-items: center;
    flex: 1;
    height: 50px;
    border-left: 1px solid #00bcff;
    border-top: 1px solid #00bcff;
    border-bottom: 1px solid #00bcff;
    .city:hover {
      cursor: pointer;
    }
    .city {
      width: 127px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;

      span {
        margin-right: 15px;
        color: #111111;
      }
    }
    .inputmain {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      /deep/ .el-input__inner {
        // border-radius: 0px;
        border: none;
        height: 100%;
        // background-color: #000080;
      }
    }
    .jineng:hover {
      cursor: pointer;
    }
    .jineng {
      width: 90px;
      height: 48px;
      // background-color: #000080;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #111111;
      .el-icon-arrow-down,
      .el-icon-arrow-up {
        margin-left: 10px;
        color: #999999;
      }
    }
    .s-btn {
      width: 90px;
      color: #ffffff;
      background-color: #00bcff;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
