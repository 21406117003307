export let options = {
  expHighOptions: [
    { value: 0.5, label: "0.5年" },
    { value: 1, label: "1年" },
    { value: 1.5, label: "1.5年" },
    { value: 2, label: "2年" },
    { value: 2.5, label: "2.5年" },
    { value: 3, label: "3年" },
    { value: 3.5, label: "3.5年" },
    { value: 4, label: "4年" },
    { value: 4.5, label: "4.5年" },
    { value: 5, label: "5年" },
    { value: 5.5, label: "5.5年" },
    { value: 6, label: "6年" },
    { value: 6.5, label: "6.5年" },
    { value: 7, label: "7年" },
    { value: 7.5, label: "7.5年" },
    { value: 8, label: "8年" },
    { value: 8.5, label: "8.5年" },
    { value: 9, label: "9年" },
    { value: 9.5, label: "9.5年" },
    { value: 10, label: "10年" },
    { value: 10.5, label: "10.5年" },
    { value: 11, label: "11年" },
    { value: 11.5, label: "11.5年" },
    { value: 12, label: "12年" },
    { value: 12.5, label: "12.5年" },
    { value: 13, label: "13年" },
    { value: 13.5, label: "13.5年" },
    { value: 14, label: "14年" },
    { value: 14.5, label: "14.5年" },
    { value: 15, label: "15年" },
    { value: 15.5, label: "15.5年" },
    { value: 16, label: "16年" },
    { value: 16.5, label: "16.5年" },
    { value: 17, label: "17年" },
    { value: 17.5, label: "17.5年" },
    { value: 18, label: "18年" },
    { value: 18.5, label: "18.5年" },
    { value: 19, label: "19年" },
    { value: 19.5, label: "19.5年" },
    { value: 20, label: "20年" },
  ],
  expLowOptions: [
    { value: 0, label: "0年" },
    { value: 0.5, label: "0.5年" },
    { value: 1, label: "1年" },
    { value: 1.5, label: "1.5年" },
    { value: 2, label: "2年" },
    { value: 2.5, label: "2.5年" },
    { value: 3, label: "3年" },
    { value: 3.5, label: "3.5年" },
    { value: 4, label: "4年" },
    { value: 4.5, label: "4.5年" },
    { value: 5, label: "5年" },
    { value: 5.5, label: "5.5年" },
    { value: 6, label: "6年" },
    { value: 6.5, label: "6.5年" },
    { value: 7, label: "7年" },
    { value: 7.5, label: "7.5年" },
    { value: 8, label: "8年" },
    { value: 8.5, label: "8.5年" },
    { value: 9, label: "9年" },
    { value: 9.5, label: "9.5年" },
    { value: 10, label: "10年" },
    { value: 10.5, label: "10.5年" },
    { value: 11, label: "11年" },
    { value: 11.5, label: "11.5年" },
    { value: 12, label: "12年" },
    { value: 12.5, label: "12.5年" },
    { value: 13, label: "13年" },
    { value: 13.5, label: "13.5年" },
    { value: 14, label: "14年" },
    { value: 14.5, label: "14.5年" },
    { value: 15, label: "15年" },
    { value: 15.5, label: "15.5年" },
    { value: 16, label: "16年" },
    { value: 16.5, label: "16.5年" },
    { value: 17, label: "17年" },
    { value: 17.5, label: "17.5年" },
    { value: 18, label: "18年" },
    { value: 18.5, label: "18.5年" },
    { value: 19, label: "19年" },
    { value: 19.5, label: "19.5年" },
  ],
  gzLowOptions: [
    { value: 3000, label: "3k" },
    { value: 3500, label: "3.5k" },
    { value: 4000, label: "4k" },
    { value: 4500, label: "4.5k" },
    { value: 5000, label: "5k" },
    { value: 5500, label: "5.5k" },
    { value: 6000, label: "6k" },
    { value: 6500, label: "6.5k" },
    { value: 7000, label: "7k" },
    { value: 7500, label: "7.5k" },
    { value: 8000, label: "8k" },
    { value: 8500, label: "8.5k" },
    { value: 9000, label: "9k" },
    { value: 9500, label: "9.5k" },
    { value: 10000, label: "10k" },
    { value: 10500, label: "10.5k" },
    { value: 11000, label: "11k" },
    { value: 11500, label: "11.5k" },
    { value: 12000, label: "12k" },
    { value: 12500, label: "12.5k" },
    { value: 13000, label: "13k" },
    { value: 13500, label: "13.5k" },
    { value: 14000, label: "14k" },
    { value: 14500, label: "14.5k" },
    { value: 15000, label: "15k" },
    { value: 15500, label: "15.5k" },
    { value: 16000, label: "16k" },
    { value: 16500, label: "16.5k" },
    { value: 17000, label: "17k" },
    { value: 17500, label: "17.5k" },
    { value: 18000, label: "18k" },
    { value: 18500, label: "18.5k" },
    { value: 19000, label: "19k" },
    { value: 19500, label: "19.5k" },
    { value: 20000, label: "20k" },
    { value: 20500, label: "20.5k" },
    { value: 21000, label: "21k" },
    { value: 21500, label: "21.5k" },
    { value: 22000, label: "22k" },
    { value: 22500, label: "22.5k" },
    { value: 23000, label: "23k" },
    { value: 23500, label: "23.5k" },
    { value: 24000, label: "24k" },
    { value: 24500, label: "24.5k" },
    { value: 25000, label: "25k" },
    { value: 25500, label: "25.5k" },
    { value: 26000, label: "26k" },
    { value: 26500, label: "26.5k" },
    { value: 27000, label: "27k" },
    { value: 27500, label: "27.5k" },
    { value: 28000, label: "28k" },
    { value: 28500, label: "28.5k" },
    { value: 29000, label: "29k" },
    { value: 30000, label: "30k" },
    { value: 30500, label: "30.5k" },
    { value: 31000, label: "31k" },
    { value: 31500, label: "31.5k" },
    { value: 32000, label: "32k" },
    { value: 32500, label: "32.5k" },
    { value: 33000, label: "33k" },
    { value: 33500, label: "33.5k" },
    { value: 34000, label: "34k" },
    { value: 34500, label: "34.5k" },
    { value: 35000, label: "35k" },
    { value: 35500, label: "35.5k" },
    { value: 36000, label: "36k" },
    { value: 36500, label: "36.5k" },
    { value: 37000, label: "37k" },
    { value: 37500, label: "37.5k" },
    { value: 38000, label: "38k" },
    { value: 38500, label: "38.5k" },
    { value: 39000, label: "39k" },
    { value: 39500, label: "39.5k" },
    { value: 40000, label: "40k" },
    { value: 40500, label: "40.5k" },
    { value: 41000, label: "41k" },
    { value: 41500, label: "41.5k" },
    { value: 42000, label: "42k" },
    { value: 42500, label: "42.5k" },
    { value: 43000, label: "43k" },
    { value: 43500, label: "43.5k" },
    { value: 44000, label: "44k" },
    { value: 44500, label: "44.5k" },
    { value: 45000, label: "45k" },
    { value: 45500, label: "45.5k" },
    { value: 46000, label: "46k" },
    { value: 46500, label: "46.5k" },
    { value: 47000, label: "47k" },
    { value: 47500, label: "47.5k" },
    { value: 48000, label: "48k" },
    { value: 48500, label: "48.5k" },
    { value: 49000, label: "49k" },
    { value: 49500, label: "49.5k" },
    { value: 50000, label: "50k" },
    { value: 50500, label: "50.5k" },
    { value: 51000, label: "51k" },
    { value: 51500, label: "51.5k" },
    { value: 52000, label: "52k" },
    { value: 52500, label: "52.5k" },
    { value: 53000, label: "53k" },
    { value: 53500, label: "53.5k" },
    { value: 54000, label: "54k" },
    { value: 54500, label: "54.5k" },
    { value: 55000, label: "55k" },
    { value: 55500, label: "55.5k" },
    { value: 56000, label: "56k" },
    { value: 56500, label: "56.5k" },
    { value: 57000, label: "57k" },
    { value: 57500, label: "57.5k" },
    { value: 58000, label: "58k" },
    { value: 58500, label: "58.5k" },
    { value: 59000, label: "59k" },
    { value: 59500, label: "59.5k" },
  ],
  gzHighOptions: [
    { value: 3000, label: "3k" },
    { value: 3500, label: "3.5k" },
    { value: 4000, label: "4k" },
    { value: 4500, label: "4.5k" },
    { value: 5000, label: "5k" },
    { value: 5500, label: "5.5k" },
    { value: 6000, label: "6k" },
    { value: 6500, label: "6.5k" },
    { value: 7000, label: "7k" },
    { value: 7500, label: "7.5k" },
    { value: 8000, label: "8k" },
    { value: 8500, label: "8.5k" },
    { value: 9000, label: "9k" },
    { value: 9500, label: "9.5k" },
    { value: 10000, label: "10k" },
    { value: 10500, label: "10.5k" },
    { value: 11000, label: "11k" },
    { value: 11500, label: "11.5k" },
    { value: 12000, label: "12k" },
    { value: 12500, label: "12.5k" },
    { value: 13000, label: "13k" },
    { value: 13500, label: "13.5k" },
    { value: 14000, label: "14k" },
    { value: 14500, label: "14.5k" },
    { value: 15000, label: "15k" },
    { value: 15500, label: "15.5k" },
    { value: 16000, label: "16k" },
    { value: 16500, label: "16.5k" },
    { value: 17000, label: "17k" },
    { value: 17500, label: "17.5k" },
    { value: 18000, label: "18k" },
    { value: 18500, label: "18.5k" },
    { value: 19000, label: "19k" },
    { value: 19500, label: "19.5k" },
    { value: 20000, label: "20k" },
    { value: 20500, label: "20.5k" },
    { value: 21000, label: "21k" },
    { value: 21500, label: "21.5k" },
    { value: 22000, label: "22k" },
    { value: 22500, label: "22.5k" },
    { value: 23000, label: "23k" },
    { value: 23500, label: "23.5k" },
    { value: 24000, label: "24k" },
    { value: 24500, label: "24.5k" },
    { value: 25000, label: "25k" },
    { value: 25500, label: "25.5k" },
    { value: 26000, label: "26k" },
    { value: 26500, label: "26.5k" },
    { value: 27000, label: "27k" },
    { value: 27500, label: "27.5k" },
    { value: 28000, label: "28k" },
    { value: 28500, label: "28.5k" },
    { value: 29000, label: "29k" },
    { value: 30000, label: "30k" },
    { value: 30500, label: "30.5k" },
    { value: 31000, label: "31k" },
    { value: 31500, label: "31.5k" },
    { value: 32000, label: "32k" },
    { value: 32500, label: "32.5k" },
    { value: 33000, label: "33k" },
    { value: 33500, label: "33.5k" },
    { value: 34000, label: "34k" },
    { value: 34500, label: "34.5k" },
    { value: 35000, label: "35k" },
    { value: 35500, label: "35.5k" },
    { value: 36000, label: "36k" },
    { value: 36500, label: "36.5k" },
    { value: 37000, label: "37k" },
    { value: 37500, label: "37.5k" },
    { value: 38000, label: "38k" },
    { value: 38500, label: "38.5k" },
    { value: 39000, label: "39k" },
    { value: 39500, label: "39.5k" },
    { value: 40000, label: "40k" },
    { value: 40500, label: "40.5k" },
    { value: 41000, label: "41k" },
    { value: 41500, label: "41.5k" },
    { value: 42000, label: "42k" },
    { value: 42500, label: "42.5k" },
    { value: 43000, label: "43k" },
    { value: 43500, label: "43.5k" },
    { value: 44000, label: "44k" },
    { value: 44500, label: "44.5k" },
    { value: 45000, label: "45k" },
    { value: 45500, label: "45.5k" },
    { value: 46000, label: "46k" },
    { value: 46500, label: "46.5k" },
    { value: 47000, label: "47k" },
    { value: 47500, label: "47.5k" },
    { value: 48000, label: "48k" },
    { value: 48500, label: "48.5k" },
    { value: 49000, label: "49k" },
    { value: 49500, label: "49.5k" },
    { value: 50000, label: "50k" },
    { value: 50500, label: "50.5k" },
    { value: 51000, label: "51k" },
    { value: 51500, label: "51.5k" },
    { value: 52000, label: "52k" },
    { value: 52500, label: "52.5k" },
    { value: 53000, label: "53k" },
    { value: 53500, label: "53.5k" },
    { value: 54000, label: "54k" },
    { value: 54500, label: "54.5k" },
    { value: 55000, label: "55k" },
    { value: 55500, label: "55.5k" },
    { value: 56000, label: "56k" },
    { value: 56500, label: "56.5k" },
    { value: 57000, label: "57k" },
    { value: 57500, label: "57.5k" },
    { value: 58000, label: "58k" },
    { value: 58500, label: "58.5k" },
    { value: 59000, label: "59k" },
    { value: 59500, label: "59.5k" },
    { value: 60000, label: "60k" },
  ],
  skillTypes: [
    {value: 0, label:"IT相关技能",},
    {value: 1, label:"芯片相关技能",},
    // {value: 2, label:"其他类型技能",}
  ],
};
