<template>
  <div class="content" ref="pronbit">
    <div class="topbian" v-if="headerstuts">
      <hidetop
        :pageInfo="pageInfo"
        :skillList="skillList"
        :wantlist="wantlist"
        :selectEducations="selectEducations"
        :expHighOptions="expHighOptions"
        :expLowOptions="expLowOptions"
        :educationOptions="educationOptions"
        :gzHighOptions="gzHighOptions"
        :gzLowOptions="gzLowOptions"
      >
      </hidetop>
    </div>
    <div class="screenbox">
      <div class="screenbox-mian font16">
        <div class="searchbox">
          <div class="sb-one">
            <!-- @click="cityshow()" -->
            <div class="city font16" @click="cityshow">
              <span>{{ pageInfo.city }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="inputmain">
              <el-input
                v-model="pageInfo.keyword"
                placeholder="推荐搜索：企业名称、岗位职能、技能标签等"
              ></el-input>
            </div>
            <!-- 技能 -->
            <div class="li qiye-type">
              <div class="tit" style="cursor: pointer" @click="jineng()">
                技能<i class="el-icon-arrow-down"></i>
              </div>
              <span v-for="(item5, index5) in skillList" :key="index5">{{
                item5.label
              }}</span>
            </div>
            <div class="s-btn" @click="toresult()">搜索</div>
          </div>
        </div>

        <!-- 求职意向 -->
        <div class="li qiye-type" v-if="isLogin && wantlist.length > 0">
          <div class="tit">求职意向:</div>
          <span
            :class="pageInfo.userJobId == item5.id ? 'active-c' : ''"
            v-for="(item5, index5) in wantlist"
            :key="index5"
            @click="wantJobChange(item5.id)"
            >{{ item5.jobName }}</span
          >
        </div>
        <!-- 周期 -->
        <div class="cycle font16">
          <div class="cycle-main">
            <div class="font14">项目周期:</div>
            <el-date-picker
              type="date"
              placeholder="开始时间"
              v-model="pageInfo.start"
              style="width: 182px; margin-left: 10px"
            >
            </el-date-picker>
            <span style="margin: 0 15px">-</span>
            <el-date-picker
              type="date"
              placeholder="结束时间"
              v-model="pageInfo.end"
              style="width: 182px"
            >
            </el-date-picker>
          </div>
          <div class="clear" @click="clearPageInfo">清空筛选条件</div>
        </div>
        <!-- 地区 -->
        <!-- <div class="li qiye-type">
					<div class="tit">工作地点:</div>
					<span :class="areanum == item4.id ? 'active-c' : ''" v-for="(item4, index4) in arealist" :key="index4" @click="areanum = item4.id">{{ item4.name }}</span>
				</div> -->
        <!-- 薪资和学历和经验 -->
        <div class="preparation">
          <!-- <div class="preparation-main" v-if="!xinzistuts">
						<el-dropdown @command="handleCommandxinzi">
							<span class="el-dropdown-link">
								薪资
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>不限</el-dropdown-item>
								<el-dropdown-item>2K以下</el-dropdown-item>
								<el-dropdown-item>2~3K</el-dropdown-item>
								<el-dropdown-item>3~5K</el-dropdown-item>
								<el-dropdown-item>5~6K</el-dropdown-item>
								<el-dropdown-item command="mpre">.....</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div> -->
          <div class="zidingyi">
            <div class="d-name">薪资：</div>
            <div class="d-input">
              <el-select
                :value="salaryValue"
                placeholder="请选择薪资"
                @change="onSelect"
                style="width: 130px"
              >
                <el-option label="不限" value=""></el-option>
                <el-option label="3K以下" :value="3000"></el-option>
                <el-option label="3-5K" :value="5000"></el-option>
                <el-option label="5-10K" :value="10000"></el-option>
                <el-option label="10-15K" :value="15000"></el-option>
                <el-option label="15-20K" :value="20000"></el-option>
                <el-option label="20-30K" :value="30000"></el-option>
                <el-option label="30-50K" :value="50000"></el-option>
                <el-option label="50K以上" :value="60000"></el-option>
                <el-option
                  :label="
                    isCustom && pageInfo.salaryHigh > 0
                      ? parseFloat((pageInfo.salaryLow / 1000).toFixed(2)) +
                        '-' +
                        parseFloat((pageInfo.salaryHigh / 1000).toFixed(2)) +
                        'K'
                      : '自定义'
                  "
                  :value="0"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="zidingyi">
						<div class="d-name">薪资：</div>
						<div class="d-input">
							<el-select v-model="pageInfo.salaryLow" placeholder="请选择最低" style="width: 130px;">
								<el-option
									v-for="item in gzLowOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<span>-</span>
							<el-select v-model="pageInfo.salaryHigh" placeholder="请选择最高" style="width: 130px;">
								<el-option
									v-for="item in gzHighOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div> -->
          <div class="education-select">
            <div class="d-name">学历：</div>
            <el-select v-model="selectEducations" multiple placeholder="请选择" @change="eduSelectLimit">
              <el-option
                v-for="item in educationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="preparation-main">
						<el-dropdown>
							<span class="el-dropdown-link">
								学历
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>不限</el-dropdown-item>
								<el-dropdown-item>大专</el-dropdown-item>
								<el-dropdown-item>本科</el-dropdown-item>
								<el-dropdown-item>研究生</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div> -->
          <!-- <div class="preparation-main">
						<el-dropdown>
							<span class="el-dropdown-link">
								工作经验
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>不限</el-dropdown-item>
								<el-dropdown-item>1年以内</el-dropdown-item>
								<el-dropdown-item>1-3年</el-dropdown-item>
								<el-dropdown-item>3~5年</el-dropdown-item>
								<el-dropdown-item>5-10年</el-dropdown-item>
								<el-dropdown-item>10年以上</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div> -->
          <div class="zidingyi">
            <div class="d-name">工作经验：</div>
            <div class="d-input">
              <el-select
                :value="expValue"
                placeholder="请选择工作经验"
                style="width: 130px"
                @change="onSelect2"
              >
                <el-option label="不限" value=""></el-option>
                <el-option label="1年以内" :value="1"></el-option>
                <el-option label="1-3年" :value="3"></el-option>
                <el-option label="3~5年" :value="5"></el-option>
                <el-option label="5-10年" :value="10"></el-option>
                <el-option label="10年以上" :value="99"></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="zidingyi">
						<div class="d-name">工作经验：</div>
						<div class="d-input">
							<el-select v-model="pageInfo.expLow" placeholder="请选择最低" style="width: 130px;">
								<el-option
									v-for="item in expLowOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<span>-</span>
							<el-select v-model="pageInfo.expHigh" placeholder="请选择最高" style="width: 130px;">
								<el-option
									v-for="item in expHighOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div> -->
        </div>
      </div>
    </div>
    <div class="contentmain">
      <div class="p-left" v-if="isLogin">
        <div
          class="pc-main"
          :class="jobConfig.isHand == itemes.id ? 'dianji' : ''"
          v-for="(itemes, index2) in jobNav"
          :key="index2"
          @click="choosetj(itemes.id)"
        >
          <span>{{ itemes.name }}</span>
        </div>
      </div>
      <!-- 无结果 -->
      <div class="nodata" v-if="jobList != null && jobList.length == 0">
        <img src="../../assets/images/common/position-nodata.png" />
        <div class="font18" v-if="wantlist.length == 0 && isLogin">
          未匹配到您的求职意向， 请先去简历中心填写求职意向
        </div>
        <div class="font18" v-else>
          {{
            loading
              ? "加载中"
              : userData != null && userData.workStatus == 1
              ? "您已在职无法查看握手信息"
              : "暂无匹配的职位"
          }}
        </div>
      </div>
      <!-- 有结果 -->
      <div class="" style="padding-bottom: 1px" v-if="jobList != null && jobList.length > 0">
        <div class="positionlist">
          <el-card
            class="box-card p-list"
            v-for="(itemli, indexes) in jobList"
            :key="indexes"
          >
            <div class="p-listbox">
              <div class="pl-left font14" @click="todetail(itemli.id)">
                <div class="l-one">
                  <div class="font16">
                    {{ itemli.jobName }}
                    <span class="short-name">{{ itemli.shortName }}</span>
                    <span class="icon" v-if="itemli.jobType == 1">Free</span>
                  </div>
                </div>
                <div class="l-two">
                  {{ itemli.city }} |
                  {{
                    itemli.educationType.length
                      ? itemli.educationType[0].itemText
                      : " - "
                  }}
                  | {{ itemli.expLow }}-{{ itemli.expHigh }}年 |
                  {{ (itemli.salaryLow / 1000) | numFilter }}K-{{ (itemli.salaryHigh / 1000) | numFilter }}K
                </div>
                <div class="l-three" v-if="itemli.jobType == 1">
                  项目周期：{{
                    itemli.startDate ? itemli.startDate.split(" ")[0] : "/"
                  }}
                  —
                  {{ itemli.endDate ? itemli.endDate.split(" ")[0] : "/" }}
                </div>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="pl-right font14">
                <div class="time" @click="todetail(itemli.id)">
                  {{ itemli.updateDate.split(" ")[0] }}发布
                </div>
                <div class="synum" @click="todetail(itemli.id)">
                  剩余招聘:{{ itemli.jobFree }}
                </div>
                <!-- <div class="czbtn">
									<el-button type="primary">投递该职位</el-button>
									<el-popover placement="bottom" width="150" trigger="click">
										<div class="downcode ">
											<img src="../../assets/logo.png" />
											<div class="downcodetext font14">快去扫码下载 APP操作吧！</div>
										</div>
										<el-button slot="reference">接受握手</el-button>
									</el-popover>
								</div> -->
              </div>
            </div>
          </el-card>
          <div class="nextpage" @click="getMoreList" v-if="moreBtn">
            点击加载更多
          </div>
        </div>
        <!-- <div class="paginate" style="position: relative;">
					<el-pagination
						@current-change="handleCurrentChange"
						:current-page="pageInfo.pageNum"
						:page-size="pageInfo.pageSize"
						layout="total, prev, pager, next, jumper"
						:total="pageInfo.count"
					></el-pagination>
				</div> -->
        <el-dialog
          title=""
          :visible.sync="dialogVisible"
          :modal-append-to-body="true"
          :append-to-body="true"
          width="30%"
        >
          <div style="padding: 0 20px">
            <p style="text-align: center; font-size: 20px; padding-top: 20px">
              请输入您想要的薪资(元)
            </p>
            <el-form
              :model="pageInfo"
              ref="numberValidateForm"
              label-width="100px"
            >
              <el-form-item
                label="最低薪资"
                prop="salaryLow"
                :rules="[{ type: 'number', message: '薪资必须为数字值' }]"
              >
                <el-input
                  type="age"
                  v-model.number="pageInfo.salaryLow"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="最大薪资"
                prop="salaryHigh"
                :rules="[{ type: 'number', message: '薪资必须为数字值' }]"
              >
                <el-input
                  type="age"
                  v-model.number="pageInfo.salaryHigh"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="customSalaryConfirm"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import { options } from "../../common/options.js";
import hidetop from "./hidetop.vue";
export default {
  components: {
    hidetop,
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        city: "北京市",
        userJobId: "",
        skillIds: "",
        start: "",
        end: "",
        salaryLow: "",
        salaryHigh: "",
        educationTypes: "",
        expLow: "",
        expHigh: "",
        keyword: "",
      },
      tn: false,
      skillList: [],
      selectEducations: [],
      educationOptions: [],
      expLowOptions: options.expLowOptions,
      expHighOptions: options.expHighOptions,
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      areanum: 1,
      arealist: [
        { id: 1, name: "上城区" },
        { id: 2, name: "下城区" },
        { id: 3, name: "萧山区" },
        { id: 4, name: "临平区" },
        { id: 5, name: "西湖区" },
        { id: 6, name: "余杭区" },
        { id: 7, name: "临安区" },
        { id: 8, name: "富阳区" },
      ],
      wantnum: 1,
      wantlist: [],
      rollheight: 0,
      headerstuts: false,
      valuell: ["技能"],
      jobNav: [
        { id: 0, name: "未握手" },
        { id: 1, name: "已握手" },
        { id: -1, name: "再等等握手" },
      ],
      jobList: null,
      moreBtn: false,
      jobConfig: {
        pageNo: 1,
        pageSize: 10,
        userJobId: "",
        isHand: 0,
      },
      isLogin: false,
      isCustom: false,
      salaryValue: "",
      expValue: "",
      userData: null,
      loading: true,
    };
  },

  created() {
    let isLogin = sessionStorage.getItem("userInfo");
    this.isLogin = isLogin;
    if (sessionStorage.getItem("selectCity")) {
      this.pageInfo.city = sessionStorage.getItem("selectCity");
    } else {
      this.getLocation();
    }
    if (isLogin) {
      this.getWantJob();
    } else {
      this.getNewJobList();
    }
    this.getEducation();
    if (isLogin) {
      this.$api.getUserData("get").then((res) => {
        this.userData = res.data;
      });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    eduSelectLimit(val){
      if(val.length>2){
        this.$message.warning("最多只能匹配两项！");
        this.selectEducations.splice(-1);
      }
    },
    customSalaryConfirm(){
      this.dialogVisible = false;
      if(this.pageInfo.salaryLow > this.pageInfo.salaryHigh){
        let temp = this.pageInfo.salaryLow;
        this.pageInfo.salaryLow = this.pageInfo.salaryHigh;
        this.pageInfo.salaryHigh = temp;
      }
    },
    onSelect(e) {
      // console.log("$onSelect-----e = ", e, ", ### this.dialogVisible = ", this.dialogVisible, ", ### this.isCustom", this.isCustom);
      this.salaryValue = e;
      if (e === "") {
        this.pageInfo.salaryLow = "";
        this.pageInfo.salaryHigh = "";
      }
      if (e == 3000) {
        this.pageInfo.salaryLow = 0;
        this.pageInfo.salaryHigh = 3000;
      }
      if (e == 5000) {
        this.pageInfo.salaryLow = 3000;
        this.pageInfo.salaryHigh = 5000;
      }
      if (e == 10000) {
        this.pageInfo.salaryLow = 5000;
        this.pageInfo.salaryHigh = 10000;
      }
      if (e == 15000) {
        this.pageInfo.salaryLow = 10000;
        this.pageInfo.salaryHigh = 15000;
      }
      if (e == 20000) {
        this.pageInfo.salaryLow = 15000;
        this.pageInfo.salaryHigh = 20000;
      }
      if (e == 30000) {
        this.pageInfo.salaryLow = 20000;
        this.pageInfo.salaryHigh = 30000;
      }
      if (e == 50000) {
        this.pageInfo.salaryLow = 30000;
        this.pageInfo.salaryHigh = 50000;
      }
      if (e == 60000) {
        this.pageInfo.salaryLow = 50000;
        this.pageInfo.salaryHigh = "";
      }
      if (e === 0) {
        this.pageInfo.salaryLow = "";
        this.pageInfo.salaryHigh = "";
        this.isCustom = true;
        this.salaryValue = 0;
        this.dialogVisible = true;
      } else {
        this.isCustom = false;
      }
    },
    onSelect2(e) {
      // console.log("$onSelect2-----e = ", e);
      this.expValue = e;
      this.pageInfo.expHigh = e;
      if (e == "") {
        this.pageInfo.expLow = "";
        this.pageInfo.expHigh = "";
        this.pageInfo.workYear='工作经验不限';
      }
      if (e == 1) {
        this.pageInfo.expLow = "";
        this.pageInfo.workYear='1年以内';
      }
      if (e == 3) {
        this.pageInfo.expLow = 1;
        this.pageInfo.workYear='1-3年';
      }
      if (e == 5) {
        this.pageInfo.expLow = 3;
        this.pageInfo.workYear='3~5年';
      }
      if (e == 10) {
        this.pageInfo.expLow = 5;
        this.pageInfo.workYear='5~10年';
      }
      if (e == 99) {
        this.pageInfo.expLow = 10;
        this.pageInfo.expHigh = "";
        this.pageInfo.workYear='10年以上';
      }
      // console.log("$onSelect2----- this.pageInfo = ", this.pageInfo);
    },
    getNewJobList() {
      this.jobList = null;
      this.tab = 2;
      //获取推荐职位列表
      this.$api.getNewJob("get", this.jobConfig).then((res) => {
        this.jobList = res.data;
      });
    },
    getLocation() {
      // 获取当前位置
      const self = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        geolocation.getCityInfo((starts, res) => {
          self.pageInfo.city = res.city;
        });
      });
    },
    getWantJob() {
      //获取求职意向
      // if (this.wantlist.length == 0) {
      //   this.jobList = [];
      //   return;
      // }
      this.jobList = null;

      this.$api.getWantJob("get").then((res) => {
        this.wantlist = res.data;
        if (res.data && res.data.length > 0) {
          this.pageInfo.userJobId = res.data[0].id;
          this.jobConfig.userJobId = res.data[0].id;
          this.getJobList();
        } else {
          this.jobList = [];
        }
      });
    },
    getEducation() {
      //获取学历
      this.$api.getdict("get", { code: "education_type" }).then((res) => {
        res.data.map((item) => {
          let obj = {
            value: item.id - 0,
            label: item.name,
          };

          this.educationOptions.push(obj);
        });
        if(this.educationOptions.length === 0){
          let obj1 = {
            value: 0,
            label: "不限",
          };
          this.educationOptions.push(obj1);
        }
        // 去重
        const tmp = new Map();
        this.educationOptions = this.educationOptions.filter((a) => !tmp.has(a.value) && tmp.set(a.value, 1));
      });
    },
    cityshow() {
      //城市选择
      Bus.$emit("citystuts", {
        show: true,
        selectCity: this.pageInfo.city,
        callback: (name) => {
          this.pageInfo.city = name;
        },
      });
    },
    jineng() {
      //选择技能
      // console.log("$choose skill ---- position.index ----- skillIds list = ", this.pageInfo.skillIds);
      let model = 1;
      let event = "jinengstuts"
      let selected = this.pageInfo.skillIds;
      if(selected !== null && selected.length !== 0){
        model = 0;
        let tempId = selected[0] - 0;
        if(tempId > 3395 && tempId < 3661) {  // 通过 skill 字典中ID序列号，判断 skill 类型
          event = "chipSkillStatus"
        } else if (tempId > 3361 && tempId < 3760) {
          event = "nonTechStatus"
        }
      }
      Bus.$emit(event, {
        show: true,
        model: model,
        skillIds: selected,
        callback: (arr) => {
          // this.skillList = this.skillList.concat(arr);
          this.skillList = arr;
          let idArr = [];
          this.skillList.map((item) => {
            idArr.push(item.value);
          });
          this.pageInfo.skillIds = idArr.join(",");
        },
      });
    },
    wantJobChange(id) {
      // console.log("$wantJobChange --- / change to jobId = ", id);
      this.pageInfo.userJobId = id;
      this.jobConfig.userJobId = id;
      this.jobList = [];
      this.jobConfig.pageNo = 1;
      if (this.jobConfig.isHand == -1) {
        this.getWaitList();
      } else {
        this.getJobList();
      }
    },
    clearPageInfo() {
      //清空筛选条件
      this.pageInfo.start = "";
      this.pageInfo.end = "";
      this.pageInfo.salaryLow = "";
      this.pageInfo.salaryHigh = "";
      this.pageInfo.educationTypes = "";
      this.pageInfo.expLow = "";
      this.pageInfo.expHigh = "";
      this.selectEducations = [];
    },
    toresult() {
      if (this.wantlist.length == 0) {
        // this.$message({
        //   message: "请先设置求职意向",
        //   type: "error",
        // });
        // this.$alert(
        //   this.isLogin ? "检测到您的简历未完善，请前往简历中心填写求职意向" : "请先登录",
        //   "提示",
        //   {
        //     confirmButtonText: "确定",
        //   }
        // );
        // return;
      }
      //搜索
      // debugger
      this.pageInfo.educationTypes = this.selectEducations.join(",");

      this.selectEducations.forEach((e)=>{
        this.educationOptions.forEach((f)=>{
               if(e===f.value){
                 this.pageInfo.educations=(f.label+ (this.pageInfo.educations?this.pageInfo.educations:''))
               }
        });
      });
      // console.log("$toResult page info = ", this.pageInfo);
      let searchJobConfig = {
        selectEducations: this.selectEducations,
        pageInfo: this.pageInfo,
      };
      // console.log("$toResult 搜索param, search config = ", searchJobConfig);
      sessionStorage.setItem(
        "searchJobConfig",
        JSON.stringify(searchJobConfig)
      );
      this.$router.push("/serachresult");
    },
    getJobList() {
      //获取推荐职位列表
      this.$api.getJobList("get", this.jobConfig).then((res) => {
        this.loading = false;
        if (res.code == 10400) {
          this.tn = true;
          this.jobList = [];

          return;
        }
        if (this.jobConfig.pageNo == 1) {
          this.jobList = res.data;
        } else {
          this.jobList = this.jobList.concat(res.data);
        }
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
      });
    },
    getWaitList() {
      //获取再等等握手列表
      let config = {
        pageNo: this.jobConfig.pageNo,
        pageSize: this.jobConfig.pageSize,
        userJobId: this.jobConfig.userJobId,
      };
      this.$api.refuseJobList("get", config).then((res) => {
        this.loading = false;
        this.jobList = this.jobList.concat(res.data);
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
      });
    },
    getMoreList() {
      //下一页
      this.jobConfig.pageNo++;
      this.getJobList();
    },
    choosetj(num) {
      this.jobList = null;
      this.loading = true;
      //推荐职位导航切换
      this.jobConfig.isHand = num;
      this.jobList = [];
      this.jobConfig.pageNo = 1;
      if (num == -1) {
        this.getWaitList();
      } else {
        this.getJobList();
      }
    },
    handleScrollx() {
      // console.log('position滚动高度 safari', window.pageYOffset);
      // console.log('position滚动高度 chrome', document.body.scrollTop);
      // console.log('position滚动高度 firefox', document.documentElement.scrollTop);
      // console.log('position 距离顶部高度',this.$refs.pronbit.getBoundingClientRect().top);
      this.rollheight = this.$refs.pronbit.getBoundingClientRect().top;
      if (this.rollheight < 0) {
        this.headerstuts = true;
        // console.log("头部隐藏出来", this.headerstuts, this.rollheight);
      } else {
        this.headerstuts = false;
      }
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      // this.formInline.pageNum = this.pageInfo.pageNum;
      // this.getdata();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    todetail(id) {
      // console.log("$to detail --- / pass the id = ", id, "current pageInfo = ", this.pageInfo);
      this.$router.push({
        path: "/positiondetail",
        query: { id: id, userJobId: this.pageInfo.userJobId },
      });
    },
    // handleCommandxinzi(command) {
    //   console.log(command);
    //   this.xinzistuts = true;
    // },
  },
};
</script>

<style lang="less" scoped>
.p-left {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 20px;
  margin-top: 20px;
  .pc-main:hover {
    cursor: pointer;
    color: #00bcff;
  }
  .pc-main {
    margin-right: 40px;
    color: #666666;
  }
  .dianji {
    color: #111111;
    position: relative;
    font-weight: bold;
  }
  .dianji :after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    bottom: -16px;
    background: #00bcff;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.topbian {
  min-height: 88px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
.content {
  margin-bottom: 200px;
}
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
.screenbox {
  // height: 600px;
  background-color: #ffffff;
  padding-bottom: 20px;
  .screenbox-mian {
    width: 1200px;
    margin: 0 auto;
    transform: translate(8px, 0px);
    .searchbox {
      padding-top: 20px;
      display: flex;
      align-items: center;
      .tologin {
        width: 200px;
        height: 50px;
        border: 1px solid #00bcff;
        margin-left: 20px;
        color: #00bcff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .sb-one {
        display: flex;
        align-items: center;
        flex: 1;
        height: 50px;
        border-left: 1px solid #00bcff;
        border-top: 1px solid #00bcff;
        border-bottom: 1px solid #00bcff;
        .city:hover {
          cursor: pointer;
        }
        .city {
          width: 127px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #999999;

          span {
            margin-right: 15px;
            color: #111111;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .inputmain {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            border: none;
          }
        }
        .jineng:hover {
          cursor: pointer;
        }
        .jineng {
          width: 90px;
          height: 48px;
          // background-color: #000080;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #111111;
          position: relative;
          .el-icon-arrow-down,
          .el-icon-arrow-up {
            margin-left: 10px;
            color: #999999;
          }
          .c-main {
            position: absolute;
            top: 70px;
            display: flex;
            width: 428px;
            min-height: 400px;
            border: 1px solid #dddddd;
            z-index: 999;
            .cm-left {
              width: 33%;
              background-color: #ffffff;
              padding-top: 30px;
              .c-title {
                color: #111111;
                font-size: 18px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .t-active {
                color: #00bcff;
                font-weight: 600;
                background-color: #f8f7fc;
              }
              .t-active:before {
                position: absolute;
                content: "";
                width: 4px;
                height: 28px;
                // top: 0;
                background: #00bcff;
                left: 0px;
                transform: translate(0%, 0%);
              }
            }
            .cm-center {
              width: 33%;
              background-color: #f8f7fc;
              padding-top: 30px;
              .c-title {
                color: #111111;
                font-size: 16px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .b-active {
                color: #00bcff;
              }
            }
            .cm-right {
              width: 33%;
              background-color: #f2f1f6;
              padding-top: 30px;
              .c-title {
                color: #111111;
                font-size: 16px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .c-active {
                color: #00bcff;
              }
            }
          }
        }
        .s-btn {
          width: 90px;
          color: #ffffff;
          background-color: #00bcff;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .cycle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #dddddd;
      .cycle-main {
        color: #111111;
        display: flex;
        align-items: center;
        /deep/ .el-input__inner {
          border-radius: 0px;
        }
      }
      .clear {
        color: #666666;
      }
      .clear:hover {
        cursor: pointer;
      }
    }
    .li {
      padding: 20px 0;
    }
    .qiye-type {
      color: #111111;
      // border-bottom: 1px solid #dddddd;
      margin-right: 20px;
      display: flex;
      align-items: center;
      span:hover {
        cursor: pointer;
        color: #00bcff;
      }
      span {
        margin-left: 30px;
      }
      .active-c {
        color: #00bcff;
      }
    }
    .preparation {
      display: flex;
      align-items: center;
      padding-top: 20px;
      .preparation-main {
        margin-right: 70px;
        .el-dropdown-link:hover {
          cursor: pointer;
        }
      }
      .zidingyi {
        margin-right: 50px;
        display: flex;
        align-items: center;
        .d-name {
          color: #606266;
          font-size: 14px;
          margin-right: 10px;
        }
        .d-input {
          display: flex;
          align-items: center;
          span {
            margin: 0 10px;
          }
          /deep/ .el-input__inner {
            border-radius: 0px;
          }
        }
      }
      .education-select {
        display: flex;
        align-items: center;
        margin-right: 50px;
        .d-name {
          color: #606266;
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
  }
}
.nodata {
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 130px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
.positionlist {
  margin-top: 20px;
  .p-list:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
    cursor: pointer;
  }
  .p-list {
    margin-bottom: 20px;
    .nextpage {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      cursor: pointer;
      margin-top: 15px;
    }
    .p-listbox {
      display: flex;
      align-items: center;
      /deep/.el-divider {
        background: #dddddd;
      }
      /deep/.el-divider--vertical {
        height: 24px;
      }
      .pl-left {
        display: flex;
        flex: 1;
        // align-items: center;
        flex-direction: column;
        line-height: 2;
        .l-one {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          div {
            color: #111111;
            .icon {
              color: #00bcff;
              font-size: 14px;
              background-color: #e9f9ff;
              padding: 1px 10px;
              margin-left: 10px;
            }
            .short-name {
              color: #ff8400;
              margin-left: 10px;
            }
          }
        }
        .l-two {
          color: #666666;
        }
        .l-three {
          color: #666666;
        }
      }
      .pl-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #666666;
        .time {
        }
        .synum {
          margin: 0 70px;
          span {
            color: #111111;
          }
        }
        .czbtn {
          /deep/ .el-button {
            border-radius: 0px;
            width: 100px;
            height: 36px;
            background-color: #00bcff;
            border: none;
            color: #ffffff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.downcode {
  text-align: center;
  .downcodetext {
    color: #111111;
    margin-top: 5px;
    padding: 0 30px;
  }
  img {
    width: 120px !important;
    height: 120px !important;
  }
}

.paginate {
  margin: 100px 0 60px 0 !important;
  .el-pagination {
    padding: 0 !important;
  }
}
.el-dialog__header,
.el-dialog__body {
  padding: 20px !important;
}
</style>
